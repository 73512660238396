<template>
  <div class="content-account-detail global-content-detail-all">

    <div class="global-content-detail" >
      <div ref="iHeaderDetail">
        <header-detail-component-global sTextDetail="Detalle de cuenta financiera: " sBackHref="Account"
          :dAccountBalance="dAccountBalance" :sCurrencyType="sCurrencyType" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <v-tabs v-model="tab" class="global-tabs" :touchless="true" center-active background-color="transparent">
        <v-tab class="tab-account-detail" v-for="item in tabsItems" :key="item">
          {{ item }}
        </v-tab>
        <v-tabs-slider class="divider-tabs"></v-tabs-slider>
      </v-tabs>
      <v-tabs-items v-model="tab" class="global-item-tab">
        <v-tab-item>
          <general-information-account-component :iTab="tab" @setAccountBalanc="setAccountBalanc"
            @setCurrencyType="setCurrencyType" class="content-general-information-component" />
        </v-tab-item>
        <v-tab-item>
          <transation-component :iTab="tab" @setAccountBalanc="setAccountBalanc" class="content-transation-component" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>
<script>
import store from '../../store';

export default {
  name: "AccountDetail",
  data() {
    return {
      tab: 0,
      activeTab: 0,

      tabsItems: {
        generalInformation: "Información general",
        purchaseOrders: "Transacciones",
      },
      screenHeight: 0,
      heightMax: 0,
      itemsBreadcrumbs: [
        {
          text: "Cuentas financieras",
          disabled: false,
          to: { name: "Account" },
        },
        {
          text: "Detalle de cuenta financiera",
          disabled: true,
          to: { name: "" },
        },
      ],
      dAccountBalance: 0,
      sCurrencyType: ""
    };
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
    // setup click event for next icon
    this.clickNextTab();

    // setup click event for previous icon
    this.clickPrevTab();
  },
  updated() {
    this.matchHeight();
  },
  beforeMount() {
    this.tab = this.sTabPositionAccounts
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 90;
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    clickNextTab() {
      document
        .getElementsByClassName("v-slide-group__next")[0]
        .addEventListener("click", () => {
          this.tab = this.activeTab + 1;
        });
    },
    clickPrevTab() {
      document
        .getElementsByClassName("v-slide-group__prev")[0]
        .addEventListener("click", () => {
          this.tab = this.activeTab - 1;
        });
    },
    setAccountBalanc(dAccountBalance) {
      this.dAccountBalance = dAccountBalance;
    },
    setCurrencyType(sCurrencyType) {
      this.sCurrencyType = sCurrencyType
    }
  },
  computed: {
    sTabPositionAccounts() {
      return this.$store.state.sTabPositionAccounts;
    },
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    tab() {
      this.$store.commit("setTabPositionAccounts",this.tab)
    }
  }
};
</script>

<style scoped>
.content-general-information-component {
  padding: 0px 0px 0px 12px;
}

.content-transation-component {
  /* padding: 0px 0px 0px 12px; */
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information-component {
    padding: 0px 0px 0px 0px;
  }

  .content-transation-component {
    padding: 0px 0px 0px 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
